import PrivacyPolicyEn from "./PrivacyPolicyEn";
import PrivacyPolicyTr from "./PrivacyPolicyTr";
import SupportEn from "./SupportEn";
import SupportTr from "./SupportTr";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => {
  return (
     /* <div className="App">
      <PrivacyPolicyEn></PrivacyPolicyEn>
    </div>
    */
    <BrowserRouter>
      <Routes>
        <Route index element={<PrivacyPolicyEn />} />
        <Route path="/tr" element={<PrivacyPolicyTr />} />
        <Route path="/en" element={<PrivacyPolicyEn />} />
        <Route path="/support/tr" element={<SupportTr />} />
        <Route path="/support" element={<SupportEn />} />
        <Route path="/support/*" element={<SupportEn />} />
        <Route path="/*" element={<PrivacyPolicyEn />} />
      </Routes>
    </BrowserRouter>
  );
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);

export default App;
