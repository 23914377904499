import React from "react";

const PrivacyPolicyEn = () => {
    return (
        <div className="PrivacyPolicyEn">
            <h1 style={{display: 'flex', justifyContent: 'center'}}>Trauma Relief Terms and Agreements</h1> 
            <br/><br/>
            <font size='5'> 
                <p style={{marginLeft: '20px', marginRight: '20px'}}>Welcome to Trauma Relief! Before you proceed, it is important to carefully review and understand the terms and agreements governing the use of our application. By using Trauma Relief, you agree to abide by these terms and acknowledge that failure to comply may result in termination of your access to the app.</p>
                <br/><br/>
                <ol style={{marginLeft: '20px', marginRight: '20px'}}> 
                    <li>Purpose and Functionality: 
                        <ul>
                            <li>
                                Trauma Relief is a mobile application designed to provide users with a range of tasks and exercises aimed at assisting individuals in addressing emotionally disturbing life events. These exercises are intended to aid in relaxation and emotional relief.
                            </li>
                        </ul>
                    </li>
                    <li> Understanding Trauma: 
                        <ul>
                            <li>
                                Trauma can manifest in various forms within the human psyche, including but not limited to unique images, sounds, thoughts, or emotions. These experiences often intertwine with our perceptions, thoughts, and emotions, significantly impacting our overall well-being.
                            </li>
                        </ul>
                    </li>
                    <li>EMDR Therapy: 
                        <ul>
                            <li>
                                Our application draws inspiration from Eye Movement Desensitization and Reprocessing (EMDR) therapy techniques, which have shown efficacy in unlocking and resolving trauma-related blockages within the nervous system or brain.
                            </li>
                        </ul>
                    </li>
                    <li>User Responsibility: 
                        <ul>
                            <li>
                                While Trauma Relief aims to provide assistance, it is essential to recognize that the healing process ultimately lies within the user's control. We do not guarantee a cure. Users are strongly advised to seek professional psychiatric help for comprehensive treatment tailored to their individual needs.
                            </li>
                        </ul>
                    </li>
                    <li>Medical Disclaimer: 
                        <ul>
                            <li>
                                The information, tasks, and exercises provided within the app are not intended to replace professional medical or psychological advice, diagnosis, or treatment. Users should exercise caution and refrain from making any significant decisions solely based on information obtained from the app.
                            </li>
                            <li>
                                It is crucial to consult with your own medical provider, mental health provider, or qualified medical professional regarding your treatment and any decisions related to your well-being. Commentary and other materials available via the app are not and are not intended to amount to, medical advice on which reliance should be placed.
                            </li> 
                            <li>
                                We disclaim all liability and responsibility arising from any reliance placed on such materials by any user of the app or by anyone who may be informed of any of its contents.
                            </li>
                            <li>
                                By using the app, you agree that you will not disregard or delay seeking professional, medical, mental health, or other relevant professional advice in substitution of the app.
                            </li>
                        </ul>
                    </li>
                    <li>Age Requirement:
                        <ul>
                            <li>
                                Trauma Relief is intended for use by individuals aged 13 and above. Users under the age of 18 should access the app under parental supervision or with the guidance of a qualified mental health provider.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Compliance with Laws: 
                        <ul>
                            <li>
                                Users are required to adhere to local laws and any applicable international laws while using the app. Users are required to comply with local and applicable international laws when using the application. Users must ensure that they comply with the law.
                            </li>
                        </ul>
                    </li>
                    <li>Prohibited Actions: 
                        <ul>
                            <li>
                                Users agree not to engage in any unlawful activities or attempt unauthorized access to the app. Modification, copying, reverse engineering, or any other form of tampering with the app's code or content is strictly prohibited, except as expressly permitted by law.
                            </li>
                        </ul>
                    </li>
                    <li>Disclaimer of Liability:
                        <ul>
                            <li>
                                The use of Trauma Relief is at the user's own risk. We expressly disclaim all liability arising from the use of the app, including but not limited to any direct, indirect, incidental, consequential, or punitive damages. All implied warranties related to the app, including accuracy and performance, are hereby excluded to the maximum extent permitted by law.
                            </li>
                            <li>
                                The app and the information contained within it are provided to you "as is" and "as available". You understand and agree that to the maximum extent permitted by law, Trauma Relief disclaims all liability whatsoever, whether arising in contract, tort, or otherwise in relation to the app or your use of it. All implied warranties, terms, and conditions relating to the app (whether implied by statute, common law, or otherwise), including without limitation any warranty as to completeness, accuracy, timeliness, satisfactory quality, performance, fitness for purpose, availability, quiet enjoyment, and title are hereby excluded between you and Trauma Relief.
                            </li>
                        </ul>
                    </li>
                    <li>Data Privacy: 
                        <ul>
                            <li>
                                To enhance the functionality of the app and monitor its performance, anonymous data may be collected through Google Firebase Analytics SDK. This data is used solely for analytical purposes and does not include any personally identifiable information. Users' privacy and confidentiality are strictly maintained in accordance with applicable data protection laws and regulations.
                            </li>
                            <li>
                                The app uses Google Firebase Analytics SDK’s, Google’s privacy policy is available at: <a href="http://www.google.com/policies/privacy/">http://www.google.com/policies/privacy/</a>. This data is collected in order that app usage and performance can be monitored and in order to make informed enhancements to the App.
                            </li>
                        </ul>
                    </li>
                    <li>Changes and Updates:
                        <ul>
                            <li>
                                Trauma Relief reserves the right to make changes to the app or its services at any time and for any reason without prior notice. Users are encouraged to review these terms periodically for any updates or modifications.
                            </li>
                        </ul>
                    </li>
                </ol>
                <br/><br/>
                <p style={{marginLeft: '20px', marginRight: '20px'}}>
                &emsp;&emsp;By using Trauma Relief, you acknowledge that you have read, understood, and agreed to these terms and agreements. If you do not agree with any part of these terms, please refrain from using the app.
                </p>
                <p style={{marginLeft: '20px', marginRight: '20px'}}> 
                &emsp;&emsp;Thank you for choosing Trauma Relief as part of your journey towards emotional well-being. If you have any questions or concerns regarding these terms, please do not hesitate to contact us for further assistance.
                </p>
            </font>
        </div>
    );
}

export default PrivacyPolicyEn;
