import React from "react";

const SupportTr = () => {
    return (
        <div className="SupportTr">
            <font size='5'> 
                <p style={{marginLeft: '20px', marginRight: '20px'}}>
                &emsp;&emsp;
                    Uygulama ile ilgili desteğe ihtiyaç duyarsanız lütfen <strong>support@healtheeinc.com</strong> e-posta adresine e-posta yollayınız.</p>
            </font>
        </div>
    );
}

export default SupportTr;