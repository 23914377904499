import React from "react";

const SupportEn = () => {
    return (
        <div className="SupportEn">
            <font size='5'> 
            <p style={{marginLeft: '20px', marginRight: '20px'}}>
                &emsp;&emsp;    
                    If you need support for the app, please send an email to <strong>support@healtheeinc.com</strong></p>
            </font>
        </div>
    );
}

export default SupportEn;